import axios from 'axios'

const ENDPOINTS = {
    getEstadisticasGenerales: (idCedi = {}) => axios(`cedis/${idCedi}/supercedis/estadisticas/general`),
    getProductosCedis: (idCedi = {}) => axios(`cedis/${idCedi}/supercedis/estadisticas/productos`),
    getGraficasProductosCedis: (idCedi, params = {}) => axios(`cedis/${idCedi}/supercedis/estadisticas/productos-graficas`, { params }),
    getUnidadesSolicitadasDia: (idCedi, params = {}) => axios(`cedis/${idCedi}/supercedis/estadisticas/productos-grafica-dia`, { params }),
};

export default ENDPOINTS
