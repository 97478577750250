<template>
    <div class="more-requested-product bg-white p-3 br-12 my-3">
        <p class="f-18 fr-medium text-general">Productos que más me solicitan otros Cedis</p>
        <div class="row">
            <div class="col-auto my-auto d-middle ml-lg-auto text-general">
                <i class="icon-format-list-checks f-19 mr-1" />
                <p class="f-17 fr-light">Unidades solicitadas</p>
            </div>
            <div class="col-auto">
                <div class="d-middle br-17 bg-fondo f-17 pr-3 pl-2">
                    <i class="icon-package-variant-closed text-gris f-19" />
                    <p class="fr-light text-general2">Stock actual</p>
                </div>
            </div>
        </div>
        <div class="d-flex gap-3 list-products mx-0 my-3 overflow-auto pt-3 scroll-none">
            <card-product v-for="(data,index) in productosSolicitados" :key="index" :product="data" />
            <div class="ml-auto d-none">
                <!-- <div class="see-more-opt br-12 wh-115 d-middle-center text-white cr-pointer bg-general3" @click="seeMoreProducts">
                    <p>Ver más</p>
                    <i class="icon-angle-right" />
                </div> -->
                <div class="d-middle-center text-general">
                    <i class="icon-format-list-checks f-19 mr-1" />
                    <p class="f-17 fr-light"> {{ '347' }}</p>
                </div>
                <p class="f-18 fr-medium text-center text-general"> {{ '24' }}% </p>
            </div>
        </div>
        <modal-see-more-products ref="refOpenModalSeeMore" />
    </div>
</template>
<script>
import cardProduct from '../components/cardProduct.vue'
import modalSeeMoreProducts from './modalSeeMoreProducts.vue'
export default {
    props: {
        productosSolicitados: {
            type: Array,
            default: []
        },
    },
    components:{
        cardProduct,
        modalSeeMoreProducts,
    },
    data(){
        return {
            mostRequestedProducts:[
                {
                    id: 1,
                    nombre: 'Leche Alpina',
                    img: null,
                    unidadMedida: 'Cartón',
                    cantActual: 1,
                    cantSolicitadas: 2,
                    porcentaje: 17
                },
                {
                    id: 2,
                    nombre: 'Doritos Mega Queso',
                    img: 'https://storage.googleapis.com/leeche_prueba/categoria/1021672146622bc73348bed7.335010200.297974001647036211.jpeg',
                    unidadMedida: 'Grs',
                    cantActual: 10,
                    cantSolicitadas: 250,
                    porcentaje: 17
                },
                {
                    id: 1,
                    nombre: 'Leche Alpina',
                    img: null,
                    unidadMedida: 'Cartón',
                    cantActual: 1,
                    cantSolicitadas: 2,
                    porcentaje: 17
                },
                {
                    id: 2,
                    nombre: 'Doritos Mega Queso',
                    img: 'https://storage.googleapis.com/leeche_prueba/categoria/1021672146622bc73348bed7.335010200.297974001647036211.jpeg',
                    unidadMedida: 'Grs',
                    cantActual: 10,
                    cantSolicitadas: 250,
                    porcentaje: 17
                },
                {
                    id: 1,
                    nombre: 'Leche Alpina',
                    img: null,
                    unidadMedida: 'Cartón',
                    cantActual: 1,
                    cantSolicitadas: 2,
                    porcentaje: 17
                },
                {
                    id: 2,
                    nombre: 'Doritos Mega Queso',
                    img: 'https://storage.googleapis.com/leeche_prueba/categoria/1021672146622bc73348bed7.335010200.297974001647036211.jpeg',
                    unidadMedida: 'Grs',
                    cantActual: 10,
                    cantSolicitadas: 250,
                    porcentaje: 17
                },
                {
                    id: 1,
                    nombre: 'Leche Alpina',
                    img: null,
                    unidadMedida: 'Cartón',
                    cantActual: 1,
                    cantSolicitadas: 2,
                    porcentaje: 17
                },
                {
                    id: 2,
                    nombre: 'Doritos Mega Queso',
                    img: 'https://storage.googleapis.com/leeche_prueba/categoria/1021672146622bc73348bed7.335010200.297974001647036211.jpeg',
                    unidadMedida: 'Grs',
                    cantActual: 10,
                    cantSolicitadas: 250,
                    porcentaje: 17
                },
                {
                    id: 1,
                    nombre: 'Leche Alpina',
                    img: null,
                    unidadMedida: 'Cartón',
                    cantActual: 1,
                    cantSolicitadas: 2,
                    porcentaje: 17
                },
                {
                    id: 2,
                    nombre: 'Doritos Mega Queso',
                    img: 'https://storage.googleapis.com/leeche_prueba/categoria/1021672146622bc73348bed7.335010200.297974001647036211.jpeg',
                    unidadMedida: 'Grs',
                    cantActual: 10,
                    cantSolicitadas: 250,
                    porcentaje: 17
                },
            ]
        }
    },
    methods: {
        seeMoreProducts(){
            this.$refs.refOpenModalSeeMore.toggle()
        }
    },
}
</script>
<style lang="scss">
.more-requested-product{
	background-color: transparent;
}
</style>