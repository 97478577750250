<template>
    <div>
        <div class="card-product border br-12 wh-115 position-relative">
            <div class="cant bg-fondo d-middle-center rounded-pill h-30px w-65px">
                <i class="icon-package-variant-closed f-20" />
                <p class="f-17 fr-light">{{ parseInt(product.cantidadStock) }}</p>
            </div>
            <el-tooltip placement="bottom" effect="light">
                <div slot="content" class="text-center text-general2 f-12 fr-light">
                    <p>{{ product.producto }}</p>
                    <!-- <p>Unidad de medida: {{ parseInt(product.unidades) }}</p> -->
                </div>
                <div class="border br-12 d-middle-center wh-115">
                    <img
                    :src="`${ product.imagen ? product.imagen : '/img/no-imagen/mercado.svg' }`"
                    :class="`${ product.imagen != null ? 'w-80' : 'w-100 br-12' } obj-cover`"
                    />
                </div>
            </el-tooltip>
        </div>
        <div class="d-middle-center text-general">
            <i class="icon-format-list-checks f-19 mr-1" />
            <p class="f-17 fr-light"> {{ parseInt(product.unidades) }}</p>
        </div>
        <p class="f-18 fr-medium text-center text-general"> {{ product.porcentaje }}% </p>
    </div>
</template>
<script>
export default {
	 props:{
        product : {
            type: Object,
            required: true,
            default: () => {}
        },
    },
    data(){
        return {
        }
    },
}
</script>
<style lang="scss">
.card-product{
	.cant{
		position: absolute;
		top: -14px;
		right: 23px;
	}
}
</style>