<template>
    <modal ref="modalOrderCedis" titulo="Productos más solicitados a otros Cedis" tamano="modal-lg" no-aceptar>
        <div class="row justify-content-center">
            <div class="col-10">
                <div class="list-products">
                    <div v-for="(data,index) in 30" :key="index" class="card-product-modal d-flex my-2">
                        <div class="wh-50 border br-12 d-middle-center">
                            <img src="https://storage.googleapis.com/leeche_prueba/categoria/1021672146622bc73348bed7.335010200.297974001647036211.jpeg" alt="" class="wh-45 obj-cover br-12" />
                        </div>
                        <div class="flex-fill mx-3">
                            <p class="f-15 text-general">{{ 'Leche Entera - Alpina' }}</p>
                            <p class="f-14 text-general2 fr-light">{{ '250' }} Unidades</p>
                            <el-progress :percentage="90" :show-text="false" color="#DBDBDB" :stroke-width="10" />
                        </div>
                        <p class="f-18 mt-auto text-general fr-medium">{{ '17' }}%</p>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>
<script>

export default {
    data(){
        return {
            superCedisList: [
                {
                    id:1,
                    img: null,
                    nombre: 'San luis',
                },
                {
                    id:2,
                    img: null,
                    nombre: 'Horeca Bga',
                },
                {
                    id:3,
                    img: null,
                    nombre: 'Buenos Aires',
                },
                {
                    id:4,
                    img: null,
                    nombre: 'Another one with long title',
                },
                {
                    id:5,
                    img: null,
                    nombre: 'Hogar Bga',
                },
            ],
        }
    },
    methods: {
        toggle(){
            this.$refs.modalOrderCedis.toggle();
        },
        updatePositionSuperCedis(){
        },
    }
}
</script>
