<template>
    <div class="productos-solicitados-cedis bg-white p-3 br-12 my-3">
        <div class="row">
            <div class="col-auto">
                <p class="f-18 fr-medium text-general">Productos solicitados por cedis</p>
            </div>
            <div class="col-auto ml-lg-auto">
                <el-select v-model="value" placeholder="Seleccionar" size="small" @change="productosSolicitadosCedis">
                    <el-option v-for="item in selectCedis" :key="item.id" :label="item.cedi" :value="item.id"/>
                </el-select>
            </div>
        </div>
        <echart :options="optionsGrafica" class="mb-2" />
    </div>
</template>
<script>
import Vue from 'vue'
import echarts from 'echarts'
Vue.component('echarts',echarts);
export default {
    props: {
        selectCedis: {
            type: Array,
            default: []
        },
        graficaProductosSolicitados: {
            type: Array,
            default: []
        },
        modelValue:{
            default:''
        }
    },
    data(){
        return {
        }
    },
    computed: {
        value: {
            get(){
                return this.modelValue
            },
            set(value){
                this.$emit('update:modelValue', value)
            }
        },
        richWorked(){
            const richP = {};
            for (const i in this.graficaProductosSolicitados){
                richP[i] =  {
                    backgroundColor: {
                        image: this.graficaProductosSolicitados[i]?.imagen,
                    },
                    height: 36,
                    width: 36,
                };
            }
            return richP;
        },
        dataYaxis(){
            return this.graficaProductosSolicitados.map(el => el.nombre)
        },
        dataHoras(){
            return this.graficaProductosSolicitados.map(el => el.unidades)
        },
        optionsGrafica(){
            return {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'none',
                    },
                    formatter: (param) => this.obtenerTooltip(param)
                },
                legend: {
                    show: false,
                },
                grid: {
                    top: '0px',
                    containLabel: true,
                    height: '100%',
                },
                xAxis: {
                    type: 'value',
                    splitLine: {
                        "show": false,
                    },
                },
                yAxis: {
                    type: 'category',
                    data: this.dataYaxis,
                    splitLine: {
                        "show": false,
                    },
                },
                series: [
                    {
                        name: 'Horas',
                        type: 'bar',
                        barMaxWidth: '40px',
                        emphasis: {
                            focus: 'series',
                        },
                        itemStyle: {
                            color: '#DBDBDB',
                        },
                        data: this.dataHoras,
                    },
                ],
            }
        },
    },
    mounted(){
    },
    methods: {
        productosSolicitadosCedis(id) {
            this.$emit('cambioCedisHijo', id)
        },
        obtenerTooltip(param) {
            const imagen = this.obtenerImagen(param[0].name)
            console.log("🚀 ~ file: productosSolicitadosPorCedis.vue:128 ~ obtenerTooltip ~ imagen:", imagen)
            let res = '';
            res += `<div class="p-2">
                <p class="fr-medium f-13">${param[0].name}</p>
                <div class="border br-12 wh-115 my-2">
                    <img src="${imagen}" alt="" class="w-100">
                </div>
                <p class="d-middle f-13">
                    <i class="icon-blank"></i>  ${param[0].value} Unidades
                </p>
            </div>`
            return res;
        },
        obtenerImagen(nombre) {
            return this.graficaProductosSolicitados.find(el => el.nombre == nombre)?.imagen ?? 0

        },
    },
}
</script>
<style lang="">
</style>