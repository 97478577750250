<template>
    <section class="sec-super-cedis-statistics scroll-none">
        <!-- v1 -->
        <div class="general-stadistics bg-white p-3 br-12 my-3">
            <p class="f-18 fr-medium text-general">Estadísticas generales</p>
            <div class="row my-3">
                <div class="col-sm-6 col-md-4 col-lg border-right">
                    <div class="d-flex">
                        <i class="icon-package-variant-closed text-green f-25 mt-auto" />
                        <div>
                            <p class="text-green fr-medium f-33">{{ estadisticasGenerales.productosSolicitados }}</p>
                            <p class="text-general f-14">Productos solicitados a otros Cedis</p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-4 col-lg border-right">
                    <div class="d-flex">
                        <i class="icon-format-list-checks text-green f-25 mt-auto" />
                        <div>
                            <p class="text-green fr-medium f-33">{{ estadisticasGenerales.unidadesSolicitadas }}</p>
                            <p class="text-general f-14">Unidades solicitadas a otros Cedis</p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-4 col-lg border-right">
                    <div class="d-flex">
                        <i class="icon-pedidos text-green f-18 mt-auto" />
                        <div>
                            <p class="text-green fr-medium f-33">{{ estadisticasGenerales.ordenes }}</p>
                            <p class="text-general f-14">Pedidos con solicitudes</p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-4 col-lg border-right">
                    <div class="d-flex">
                        <i class="icon-package-variant-closed text-alert f-25 mt-auto" />
                        <div>
                            <p class="text-alert fr-medium f-33">{{ estadisticasGenerales.productosEnviados }}</p>
                            <p class="text-general f-14">Productos enviados a otros cedis</p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-4 col-lg">
                    <div class="d-flex">
                        <i class="icon-format-list-checks text-alert f-25 mt-auto" />
                        <div>
                            <p class="text-alert fr-medium f-33">{{ estadisticasGenerales.unidadesEnviadas }}</p>
                            <p class="text-general f-14">Unidades enviadas a otros cedis</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Productos más solicitados a otros Cedis -->
        <mis-productos-mas-solicitados :misProductosSolicitados="misProductosSolicitados" />
        <!-- Unidades de productos solicitados a los cedis -->
        <mis-unidades-productos-solicitados :graficaUnidadesSolicitadas="graficaUnidadesSolicitadas" />
        <!-- Productos solicitados por cedis -->
        <productos-solicitados-por-cedis
            :modelValue="idCediOrigen"
            :selectCedis="selectCedis"
            :graficaProductosSolicitados="graficaProductosSolicitados"
            @cambioCedisHijo="cambioCedisHijo"
        />
        <!-- Unidades de productos solicitadas a los cedis por día -->
        <unidades-productos-solicitados-por-dia />
        <!-- Productos que más me solicitan otros Cedis -->
        <productos-mas-solicitados :productosSolicitados="productosSolicitados" />
        <!-- Historial de traslados del stock -->
        <historial-traslado-stock />
    </section>
</template>
<script>
import misProductosMasSolicitados from './partials/misProductosMasSolicitados'
import misUnidadesProductosSolicitados from './partials/misUnidadesProductosSolicitados'
import productosSolicitadosPorCedis from './partials/productosSolicitadosPorCedis.vue'
import unidadesProductosSolicitadosPorDia from './partials/unidadesProductosSolicitadosPorDia.vue'
import productosMasSolicitados from './partials/productosMasSolicitados.vue'
import historialTrasladoStock from './partials/historialTrasladoStock.vue'
import Service from '~/services/cedis/supercedis_estadisticas'
export default {
    components: {
        misProductosMasSolicitados,
        misUnidadesProductosSolicitados,
        productosSolicitadosPorCedis,
        unidadesProductosSolicitadosPorDia,
        productosMasSolicitados,
        historialTrasladoStock,
    },
    data(){
        return {
            estadisticasGenerales:{},
            selectCedis:[],
            misProductosSolicitados:[],
            productosSolicitados:[],
            graficaUnidadesSolicitadas:[],
            graficaProductosSolicitados:[],
            idCediOrigen:null
        }
    },

    mounted(){
        this.getEstadisticasGenerales()
        this.getProductosCedis()
        this.graficasProductosCedis()
    },

    methods: {
        async getEstadisticasGenerales() {
            try {
                const { data } = await Service.getEstadisticasGenerales(this.$route.params.id_cedis)
                this.estadisticasGenerales = data?.data
                this.selectCedis = data?.data?.selectCedis ?? []
                this.idCediOrigen = this.selectCedis[0]?.id ?? null
                if (this.idCediOrigen != null) this.graficasProductosCedis()
            } catch (error) {
                this.notificacion('Error', 'error al obtener datos', 'error')
            }
        },

        async getProductosCedis(){
            const { data } = await Service.getProductosCedis(this.$route.params.id_cedis)
            this.productosSolicitados = data?.data?.productosEnviados ?? []
            this.misProductosSolicitados = data?.data?.productosRecibidos ?? []
        },
        async graficasProductosCedis(){
            try {
                if (this.idCediOrigen == null) return
                const params = {
                    idCediOrigen:this.idCediOrigen
                }
                const { data } = await Service.getGraficasProductosCedis(this.$route.params.id_cedis, params)
                this.graficaUnidadesSolicitadas = data?.data?.unidadesCedis ?? []
                this.graficaProductosSolicitados = data?.data?.prodcutosCedi ?? []
            } catch (error) {
                this.notificacion('Error', 'error al obtener datos', 'error')
            }


        },
        cambioCedisHijo(id){
            this.idCediOrigen = id
            this.graficasProductosCedis()
        }

    },
}
</script>
<style lang="scss">
.sec-super-cedis-statistics{
	overflow: auto;
	height: calc(100vh - 220px);
	.general-stadistics{
		.icon-package-variant-closed, .icon-format-list-checks{
			position: relative;
			top: 8px;
		}
		.icon-pedidos{
			position: relative;
			top: 3px;
		}
	}
}
</style>