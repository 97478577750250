<template>
    <div class="productos-solicitados-cedis bg-white p-3 br-12 my-3">
        <div class="row mb-3">
            <div class="col-auto">
                <p class="f-18 fr-medium text-general">Unidades de productos solicitadas a los cedis por día</p>
            </div>
            <div class="col-auto ml-lg-auto">
                <el-date-picker v-model="fecha" format="MM/yyyy" value-format="MM/yyyy" type="month" placeholder="Pick a month" clear-icon="" @change="cambiarFechaUnidadesSolicitadas" />
                <!-- <el-select v-model="value" placeholder="Seleccionooooar" size="small">
                    <i slot="prefix" class="icon-calendar-outline f-20 mr-1 pr-2" />
                </el-select> -->
            </div>
        </div>
        <echart :options="optionsGrafica" class="mb-2" />
    </div>
</template>
<script>
import Vue from 'vue'
import echarts from 'echarts'
import Service from '~/services/cedis/supercedis_estadisticas'
import moment from "moment";
Vue.component('echarts',echarts);
export default {
    data(){
        return {
            value: '',
            dias:[],
            nombresCedis:[],
            dataBarras:[],
            fecha:moment().format('MM/yyyy'),
            params:{
                anio:null,
                mes:null
            }

        }
    },
    computed: {
        richWorked(){
            const richP = {};
            for (const i in this.dataGrafica){
                richP[i] =  {
                    backgroundColor: {
                        image: this.dataGrafica[i]?.imagen,
                    },
                    height: 36,
                    width: 36,
                };
            }
            return richP;
        },
        dataYaxis(){
            return this.dataGrafica.map(el => el.nombre)
        },
        dataHoras(){
            return this.dataGrafica.map(el => el.horas)
        },
        optionsGrafica(){
            return {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                    backgroundColor: "#fff",
                    textStyle:{
                        color: "#707070"
                    },
                    extraCssText: 'box-shadow: 0px 3px 6px #5D5D5D14; border: 1px solid #f5f5f5;'
                },
                legend: {
                    data: this.nombresCedis,
                    right: 0
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                yAxis: {
                    type: 'value'
                },
                xAxis: {
                    type: 'category',
                    data: this.dias
                },
                color: ['#FFDA56', '#FF5806', '#E10008'],
                series: this.dataBarras
            }
        },
    },
    mounted(){
        this.cambiarFechaUnidadesSolicitadas()
        this.getUnidadesSolicitadasDia()
    },
    methods: {
        async getUnidadesSolicitadasDia() {
            const { data } = await Service.getUnidadesSolicitadasDia(this.$route.params.id_cedis, this.params)
            this.dias = data?.data?.fechaMes
            this.nombresCedis = data?.data?.nombresCedis
            this.dataBarras = data?.data?.dataBarras
        },
        cambiarFechaUnidadesSolicitadas(){
            const date = this.fecha.split('/')
            this.params.mes = date[0]
            this.params.anio = date[1]
            this.getUnidadesSolicitadasDia()
        }
    },
}
</script>
<style lang="">
</style>