<template>
    <div class="unidades-productos-solicitados bg-white p-3 br-12 my-3">
        <p class="f-18 fr-medium text-general mb-3">Unidades de productos solicitados a los cedis</p>
        <echart :options="optionsGrafica" class="mb-2" />
    </div>
</template>
<script>
import Vue from 'vue'
import echarts from 'echarts'
Vue.component('echarts',echarts);
export default {
    props: {
        graficaUnidadesSolicitadas: {
            type: Array,
            default: []
        },
    },
    data(){
        return {
            dataGrafica: [
                {
                    nombre: "San Luis",
                    imagen: "/img/no-imagen/mercado.svg",
                    horas: 118
                },
                {
                    nombre: "Horeca Bga",
                    imagen: "/img/no-imagen/mercado.svg",
                    horas: 48
                },
                {
                    nombre: "Buenos Aires",
                    imagen: "/img/no-imagen/mercado.svg",
                    horas: 10
                },
            ],
        }
    },
    computed: {
        richWorked(){
            const richP = {};
            for (const i in this.graficaUnidadesSolicitadas){
                richP[i] =  {
                    backgroundColor: {
                        image: this.graficaUnidadesSolicitadas[i]?.imagen,
                    },
                    height: 36,
                    width: 36,
                };
            }
            return richP;
        },
        dataYaxis(){
            return this.graficaUnidadesSolicitadas.map(el => el.nombre)
        },
        dataHoras(){
            return this.graficaUnidadesSolicitadas.map(el => el.unidades)
        },
        optionsGrafica(){
            return {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'none',
                    },
                    backgroundColor: "#fff",
                    formatter: (param) => this.obtenerTooltip(param)
                    // formatter: function(param){
                    //     console.log("🚀 ~ file: misUnidadesProductosSolicitados.vue:79 ~ optionsGrafica ~ param:", param)
                    //     return `<div class="text-general2">
					// 		<p class="fr-medium f-13 text-general">${param[0].name}</p>
					// 		<p class="d-middle f-13">
					// 			<i class="icon-blank"></i>  ${param[0].value} Unidades
					// 		</p>
					// 		<p class="d-middle f-13">
					// 			<i class="icon-package-variant-closed"></i> ${this.obtenerProductos()}
					// 		</p>
					// 	</div>`
                    // },
                },
                legend: {
                    show: false,
                },
                grid: {
                    top: '0px',
                    containLabel: true,
                    height: '100%',
                },
                xAxis: {
                    type: 'value',
                    splitLine: {
                        "show": false,
                    },
                },
                yAxis: {
                    type: 'category',
                    data: this.dataYaxis,
                    splitLine: {
                        "show": false,
                    },
                    axisLabel: {
                        formatter: function(value, index){
                            return '{value|' + value + '} {' + index + '| }';
                        },
                        rich: this.richWorked,
                    },
                },
                series: [
                    {
                        name: 'Horas',
                        type: 'bar',
                        barMaxWidth: '40px',
                        emphasis: {
                            focus: 'series',
                        },
                        itemStyle: {
                            color: '#DBDBDB',
                            //borderRadius: [0,50,50,0],
                        },
                        data: this.dataHoras,
                    },
                ],
            }
        },
    },
    mounted(){
    },

    methods: {
        obtenerTooltip(param) {
            return `<div class="text-general2">
					    <p class="fr-medium f-13 text-general">${param[0].name}</p>
					 	<p class="d-middle f-13">
					 	    <i class="icon-blank"></i>  ${param[0].value} Unidades
					 	</p>
					 	<p class="d-middle f-13">
					 	    <i class="icon-package-variant-closed"></i> ${  this.obtenerProductos(param[0].name)  }
					    </p>
					</div>`
        },
        obtenerProductos(nombre) {
            return this.graficaUnidadesSolicitadas.find(el => el.nombre == nombre)?.productos ?? 0

        },
    },

}
</script>
<style lang="">
</style>